import React from 'react';
import './index.css';

import Intro from './pages/First/Intro';

function App() {
  return (
    <>
      <Intro />
    </>
  );
}

export default App;
