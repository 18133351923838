import React from 'react';
import './style.css';
import logo from '../../assets/logo.png';
import menu from '../../assets/menu.svg';

import be from '../../assets/icons/behance-line.png';
import dl from '../../assets/icons/dribbble-line.png';
import fb from '../../assets/icons/facebook.png';
import insta from '../../assets/icons/instagram.png';
import linkedin from '../../assets/icons/linkedin.png';

import hand from '../../assets/hand.png';

const menuEntries = ['Process', 'Team', 'Technologies'];

function Intro() {
  return (
    <div className="background-image">
      <main className="relative overflow-hidden h-screen">
        <header className="h-24 sm:h-32 flex items-center z-30 w-full">
          <div className="container mx-auto px-6 flex items-center justify-between">
            <img src={logo} alt="Logo" />
            <div className="flex items-center mr-4">
              <nav className="font-avenirMedium text-white md:text-sm lg:text-lg xl:text-xl md:flex items-center hidden">
                {menuEntries.map((item: string, index: number) => {
                  return (
                    <a key={index} href="#" className="my-2 mx-9">
                      {item}
                    </a>
                  );
                })}

                <div className="ml-2">
                  <a href="#" className="py-1.5 px-8 border border-white border-1 rounded-full">
                    Contact Us
                  </a>
                </div>
              </nav>
              <button className="md:hidden flex flex-col ml-4">
                <img src={menu} alt="" />
              </button>
            </div>
          </div>
        </header>

        <div className="hidden md:flex c-centered container  flex-row ">
          <div className="hidden md:flex flex-col justify-evenly mx-10">
            <a href="#">
              <img src={be} alt="" className="w-4 lg:w-6 mb-5  aspect-auto" />
            </a>
            <img src={dl} alt="" className="w-4 my-4 lg:w-6 aspect-auto" />
            <img src={fb} alt="" className=" w-4 my-4 lg:w-6 aspect-auto" />
            <img src={insta} alt="" className=" w-4 my-4 lg:w-6 aspect-auto" />
            <a href="https://www.linkedin.com/company/neobytes-tech/about/" target="_blank" rel="noreferrer">
              <img src={linkedin} alt="" className=" w-4 mt-5 mb-3 lg:w-6 aspect-auto" />
            </a>
          </div>
          <div className="lg:ml-20 flex flex-col justify-between w-full min-h-full relative">
            <p className="md:text-6xl lg:text-7-8xl font-avenirHeavy text-white">
              <span className="text-neoPrimary">Neo</span>
              <span>bytes Tech</span>
            </p>
            <div className="flex flex-row w-full items-center">
              <p className="md:text-3xl lg:text-4-5xl font-avenirRoman text-white">
                Simply, <span className="font-avenirBlack">Mobile Development</span> perfected
              </p>
              <img src={hand} alt="hand" className="md:w-8 lg:w-11  ml-2 mb-4 align-self-center " />
            </div>
            <div className="mx-6 md:mx-0 md:w-32 lg:w-36 font-avenirMedium border border-1 rounded-full flex justify-center">
              <p className="p-2 text-white text-2xl">Let{"'"}s talk</p>
            </div>
          </div>
        </div>

        <div className="mt-5 flex md:hidden container ">
          <div className="m-10 flex flex-col justify-between w-full min-h-full">
            <p className="text-7xl font-avenirHeavy text-white">
              <span className="text-neoPrimary">Neo</span>
              <span>
                bytes
                <br />
                Tech
              </span>
            </p>
            <br />
            <br />
            <div className="flex flex-row w-full items-center">
              <p style={{ lineHeight: '3.2rem' }} className="text-3xl font-avenirRoman text-white">
                Simply, <br />
                <span className="font-avenirBlack">Mobile Development</span> <br />
              </p>
            </div>
            <div className="flex flex-row w-full items-center">
              <p className="text-3xl font-avenirRoman text-white">perfected</p>
              <img src={hand} alt="hand" className="w-8 ml-2 mb-4 align-self-center " />
            </div>
            <div className="mt-14 font-avenirMedium border border-1 rounded-full flex justify-center">
              <p className="p-2 text-white text-2xl">Let{"'"}s talk</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Intro;
